import React, { useEffect, useState } from "react";
import products from "../data/products";
import { Modal, Button, Table } from "react-bootstrap";

function Groups() {
  const [items, setItems] = useState([]);
  const [filtredData, setFiltredData] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const response = products;

    setItems(response);
    setFiltredData(response);

    document.getElementById("searchInput").focus();
    // eslint-disable-next-line
  }, []);

  // Data filter
  const handleFilter = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    const filtered = items.filter((item) =>
      item.nombre.toLowerCase().includes(searchTerm)
    );
    setFiltredData(filtered);
  };

  // Función para agregar al carrito
  const addToCart = (product) => {
    const isInCart = cartItems.find(
      (item) => item.nombre === product.nombre
    );

    if (!isInCart) {
      setCartItems([...cartItems, product]);
    } else {
      alert("El producto ya está en el carrito");
    }
  };

  // Función para eliminar del carrito
  const removeFromCart = (product) => {
    const updatedCart = cartItems.filter(
      (item) => item.nombre !== product.nombre
    );
    setCartItems(updatedCart);
  };

  return (
    <div>
      <main className="container">
        <input
          type="text"
          id="searchInput"
          placeholder="Buscar producto"
          className="form-control"
          onChange={handleFilter}
        />
        <div className="container mt-5">
          <div className="row">
            <div className="col-md-10">
              <div className="container">
                <div className="row">
                  {filtredData.length > 0 ? (
                    filtredData.map((item, index) => {
                      // Calcular el precio mínimo y el supermercado correspondiente
                      const precios = item.precios;
                      const entries = Object.entries(precios);

                      const minEntry = entries.reduce((min, current) => {
                        return current[1] < min[1] ? current : min;
                      });

                      const [supermercadoMinimo, precioMinimo] = minEntry;

                      // Añadir precioMinimo y supermercadoMinimo al objeto 'item'
                      item.precioMinimo = precioMinimo;
                      item.supermercadoMinimo = supermercadoMinimo;

                      return (
                        <div key={item.id || index} className="col-md-3 mt-4">
                          <div className="card">
                            <img
                              src={item.url_imagen}
                              className="card-img-top"
                              alt={item.nombre}
                            />
                            <div className="card-body">
                              <h5 className="card-title fs-6">
                                {item.nombre}
                              </h5>
                              <p className="card-text">
                                Precio más barato: ${precioMinimo.toFixed(2)}
                              </p>
                              <p className="card-text">
                                Supermercado: {supermercadoMinimo}
                              </p>
                              <a
                                href="#"
                                className="btn btn-primary"
                                onClick={(e) => {
                                  e.preventDefault();
                                  addToCart(item);
                                }}
                              >
                                Agregar
                              </a>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="col-12 text-center mt-5">
                      <p>Cargando...</p>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="col-md-2">
              <h5>Lista de productos</h5>
              {cartItems.length > 0 ? (
                cartItems.map((product, index) => (
                  <div key={index}>
                    <p>
                      {product.nombre} - ${product.precioMinimo.toFixed(2)}
                      <button
                        className="btn btn-sm btn-danger ms-2"
                        onClick={() => removeFromCart(product)}
                      >
                        Eliminar
                      </button>
                    </p>
                  </div>
                ))
              ) : (
                <p>No hay productos en el carrito</p>
              )}
              {cartItems.length > 0 && (
                <Button
                  variant="primary"
                  onClick={() => setShowModal(true)}
                  className="mt-2"
                >
                  Finalizar compra
                </Button>
              )}
            </div>
          </div>
        </div>
      </main>

      {/* Modal de Comparación de Precios */}
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Comparación de Precios</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table bordered hover>
            <thead>
              <tr>
                <th>Producto</th>
                <th>DIA</th>
                <th>Carrefour</th>
                <th>Coto</th>
                <th>Jumbo</th>
              </tr>
            </thead>
            <tbody>
              {cartItems.map((product, index) => (
                <tr key={index}>
                  <td>{product.nombre}</td>
                  <td>${product.precios.DIA.toFixed(2)}</td>
                  <td>${product.precios.Carrefour.toFixed(2)}</td>
                  <td>${product.precios.Coto.toFixed(2)}</td>
                  <td>${product.precios.Jumbo.toFixed(2)}</td>
                </tr>
              ))}
              <tr>
                <td>
                  <strong>Total</strong>
                </td>
                <td>
                  <strong>
                    $
                    {cartItems
                      .reduce((total, product) => total + product.precios.DIA, 0)
                      .toFixed(2)}
                  </strong>
                </td>
                <td>
                  <strong>
                    $
                    {cartItems
                      .reduce(
                        (total, product) => total + product.precios.Carrefour,
                        0
                      )
                      .toFixed(2)}
                  </strong>
                </td>
                <td>
                  <strong>
                    $
                    {cartItems
                      .reduce((total, product) => total + product.precios.Coto, 0)
                      .toFixed(2)}
                  </strong>
                </td>
                <td>
                  <strong>
                    $
                    {cartItems
                      .reduce((total, product) => total + product.precios.Jumbo, 0)
                      .toFixed(2)}
                  </strong>
                </td>
              </tr>
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Groups;

const products = [
    {
        "nombre": "Mayonesa Cl\u00e1sica Hellmann's Sin Tacc Doypack 475 Gr.",
        "precios": {
            "DIA": 1375.55,
            "Carrefour": 2005.0,
            "Coto": 1498.94,
            "Jumbo": 1507.50
        },
        "url_imagen": "https://ardiaprod.vtexassets.com/arquivos/ids/325528/Mayonesa-Clasica-Hellmann-s-Sin-Tacc-Doypack-475-Gr-_1.jpg?v=638599582731000000"
    },
    {
        "nombre": "Caldo Verdura Con Vegetales Knorr 12 Ud.",
        "precios": {
            "DIA": 1550.00,
            "Carrefour": 1890.0,
            "Coto": 1398.50,
            "Jumbo": 1980.0
        },
        "url_imagen": "https://ardiaprod.vtexassets.com/arquivos/ids/309318/Caldo-Verdura-Con-Vegetales-Knorr-12-Ud-_1.jpg?v=638599355414400000"
    },
    {
        "nombre": "Sal Fina en Paquete Celusal 500 Gr.",
        "precios": {
            "DIA": 950.00,
            "Carrefour": 1219.5,
            "Coto": 1274.00,
            "Jumbo": 1280.00
        },
        "url_imagen": "https://ardiaprod.vtexassets.com/arquivos/ids/309071/Sal-Fina-en-Paquete-Celusal-500-Gr-_1.jpg?v=638599351982970000"
    },
    {
        "nombre": "Ketchup Cl\u00e1sico Hellmann's Doypack 250 Gr.",
        "precios": {
            "DIA": 1260.00,
            "Carrefour": 1700.00,
            "Coto": 1840.00,
            "Jumbo": 1771.00
        },
        "url_imagen": "https://ardiaprod.vtexassets.com/arquivos/ids/309272/Ketchup-Clasico-Hellmann-s-Doypack-250-Gr-_1.jpg?v=638599354846100000"
    },
    {
        "nombre": "Chocolate Milka Chocopause 45 Gr.",
        "precios": {
            "DIA": 1470.00,
            "Carrefour": 954.0,
            "Coto": 1467.99,
            "Jumbo": 930.00
        },
        "url_imagen": "https://ardiaprod.vtexassets.com/arquivos/ids/324050/Chocolate-Milka-Chocopause-45-Gr-_1.jpg?v=638599563979530000"
    },
    {
        "nombre": "Nesquik 300g",
        "precios": {
            "DIA": 3030.00,
            "Carrefour": 3030.00,
            "Coto": 3030.00,
            "Jumbo": 3300.00
        },
        "url_imagen": "https://ardiaprod.vtexassets.com/arquivos/ids/324350/Arroz-Largo-Fino-Primor-1kg.jpg?v=638599563979530000"
    },
    {
        "nombre": "Cheetos 140g",
        "precios": {
            "DIA": 2517.00,
            "Carrefour": 2820.00,
            "Coto": 3691.99,
            "Jumbo": 2610.00
        },
        "url_imagen": "https://ardiaprod.vtexassets.com/arquivos/ids/318359/Chizitos-De-Maiz-Sabor-A-Queso-Cheetos-Original-X-140-Gr-_1.jpg?v=638599488295630000"
    },
    {
        "nombre": "Arroz Molino Ala 500g",
        "precios": {
            "DIA": 1105.00,
            "Carrefour": 1500,
            "Coto": 1700.00,
            "Jumbo": 1550.00
        },
        "url_imagen": "https://ardiaprod.vtexassets.com/arquivos/ids/329271/Pan-Molde-Bimbo-Integral-600g.jpg?v=638599654328000000"
    }
];
  
  export default products;